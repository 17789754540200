import {isDev} from "@/common/other";

export const site_config = {
    title:process.env.VUE_APP_TITLE,
    cookie_prefix:process.env.VUE_APP_COOKIE_PREFIX || 'cookie',
    case_type:[
        {id: 1, title: "品牌网站"}, {id: 2, title: "云会议"}, {id: 3, title: "H5游戏"}, {id: 4, title: "小程序"}, {id: 5, title: "数据大屏"}
    ]
}
export const ext_config = {
    file:".pdf,.ppt,.doc,.docx,.word,.xlxs,.xls",
    image:".jpg,.jpeg,.png,.gif,.svg,.tiff",
    video:".mp4,.mov,.ogg",
}

export const host_config = {
    api:isDev() ? process.env.VUE_APP_API_HOST : process.env.VUE_APP_API_HOST,
}

export const sentry  ={
    type:['JAVASCRIPT', 'VUE', 'REACT', 'PROMISE', 'ROUTE', 'RESOURCE', 'HTTP', 'LOG', 'UNKNOWN', 'UNKNOWN_FUNCTION','NODE']
}
