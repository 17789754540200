import jsCookie from "js-cookie"
import {site_config} from "@/config/index.js";
export default {
	namespaced: true,
	state: {
		token:"",
		account_info:{},
		project:{},
	},
	getters:{
		getToken(state){
			let token = state.token;
			if(!token){
				token = jsCookie.get(`${site_config.cookie_prefix}_sentry_token`) ;
			}
			return token;
		},
		getAccountInfo(state){
			let account_info = state.account_info;
			if(!account_info){
				account_info = jsCookie.get(`${site_config.cookie_prefix}_sentry_account_info`) ;
				if(account_info){
					account_info = decodeURIComponent(JSON.parse(account_info))
				}
			}
			return account_info;
		}
	},
	mutations: {
		setToken(state,token){
			state.token = token;
			jsCookie.set(`${site_config.cookie_prefix}_sentry_token`,token)
		},
		setAccountInfo(state,account_info){
			state.account_info = account_info;
			jsCookie.set(`${site_config.cookie_prefix}_sentry_account_info`,encodeURIComponent(JSON.stringify(account_info)))
		},
		setProject(state,project){
			state.project = project;
		}
	}
}
