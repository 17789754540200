import store from "@/store";
import {auth, views} from "@/api/views.js";
import {createUUID} from "@/common/string.js";
import {site_config} from "@/config/index.js";
import router from "@/router";

export const toError = (error)=>{
	router.replace({
		path:"/error",
		query:error
	})
}

export const createViewLog = ()=>{
	views.log({
		source: 1,
		title: document.title,
		ua: navigator.userAgent,
		url: window.location.href,
	}).then(()=>{})
}

export const getUUID = ()=>{
	let uuid = localStorage.getItem('UUID');
	if(!uuid){
		uuid = createUUID();
		localStorage.setItem('UUID',uuid);
	}
	return uuid;
}

export const doViewsRegister = async ()=>{
	if(!getViewsToken()){
		const uuid = getUUID();
		const p1 = await auth.register({
			uuid:uuid,
			source:1
		})
		store.commit('views/setToken',p1.token)
		store.commit('views/setUserInfo',p1.user_info)
	}
}

export const doViewsLogin = async ()=>{
	if(!getViewsToken()){
		const uuid = getUUID();
		const p1 = await auth.login({
			uuid:uuid,
			source:1,
		})
		store.commit('views/setToken',p1.token)
		store.commit('views/setUserInfo',p1.user_info)
	}
}

export const getViewsToken = ()=>{
	return store.getters['views/getToken'] || ''
}

export const getUserInfo = ()=>{
	return store.getters['views/getUserInfo'] || ''
}

export const setTitle = (title )=>{
	document.title = title || site_config.title;
}
