import {getAdminToken} from "@/service/admin/common.js";

export default [
	{
		path: '/admin/auth/login',
		component: ()=> import('@/views/admin/auth/Login.vue'),
		meta: {title: '登录'},
	},
	{
		path:"/admin",
		component:()=> import('@/layout/Admin.vue'),
		children:[
			{
				path: '/admin',
				component: ()=> import('@/views/admin/dashboard/Index.vue'),
				meta: {title: '控制台'},
			},
			{
				path: '/admin/home/cases',
				component: ()=> import('@/views/admin/home/cases/Index.vue'),
				meta: {title: '案例管理'},
			},
			{
				path: '/admin/home/partner',
				component: ()=> import('@/views/admin/home/partner/Index.vue'),
				meta: {title: '合作伙伴'},
			},
			{
				path: '/admin/home/article',
				component: ()=> import('@/views/admin/home/article/Index.vue'),
				meta: {title: '文章管理'},
			},
			{
				path: '/admin/home/message',
				component: ()=> import('@/views/admin/home/message/Index.vue'),
				meta: {title: '留言管理'},
			},
			{
				path: '/admin/open/wechat',
				component: ()=> import('@/views/admin/open/wechat/Index.vue'),
				meta: {title: '微信公众号'},
			},
			{
				path: '/admin/sentry/account',
				component: ()=> import('@/views/admin/sentry/account/Index.vue'),
				meta: {title: '账号管理'},
			},
			{
				path: '/admin/admin',
				component: ()=> import('@/views/admin/admin/Index.vue'),
				meta: {title: '管理员'},
			},
			{
				path: '/admin/config',
				component: ()=> import('@/views/admin/config/Index.vue'),
				meta: {title: '系统配置'},
			},
			{
				path: '/admin/email/template',
				component: ()=> import('@/views/admin/email/template/Index.vue'),
				meta: {title: '邮件模板'},
			},
			{
				path: '/admin/user',
				component: ()=> import('@/views/admin/user/Index.vue'),
				meta: {title: '用户管理'},
			},
			{
				path: '/admin/*',
				name: 'Error',
				component: ()=> import('@/views/admin/Error.vue'),
				meta: {title: '错误'},
			}
		],
		beforeEnter:(to,from,next)=>{
			if(!getAdminToken()){
				next('/admin/auth/login')
			}else{
				next();
			}
		}
	}
]
