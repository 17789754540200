import Vue from 'vue'
import App from '@/App.vue'
import router from '@/router'
import store from '@/store'
import directive from "@/directive";
import plugins from "@/plugins";

Vue.config.productionTip = false;

MITO.init({"debug":false,"disabled":false,"vue":Vue,"dsn":"https://www.site.com/sentry/report/event","apikey":"8c7e8edf4e3e4bd1cd264294849b740f37105f1a","silentConsole":false,"silentXhr":false,"silentFetch":false,"filterXhrUrlRegExp":"","maxBreadcrumbs":20},[MITO.vuePlugin]);

new Vue({
    router,
    store,
    directive,
    plugins,
    render: h => h(App)
}).$mount('#app')
