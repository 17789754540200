import {request} from '@/common/request/views.js'

export const wxSdk = {
	jssdk(params){
		return request.get('/api/wechat/jssdk',{params})
	}
}

export const config = {
	get(){
		return request.get('/api/config/get')
	}
}

export const auth = {
	register(data){
		return request.post('/api/auth/register',data)
	},
	login(data){
		return request.post('/api/auth/login',data)
	}
}

export const views = {
	log(data){
		return request.post('/api/view/log',data)
	}
}

export const user = {
	info(params){
		return request.get('/api/user/info', {params})
	}
}

export const cases = {
	all(params){
		return request.get('/api/cases/all', {params})
	},
	get(params){
		return request.get('/api/cases/get', {params})
	},
}

export const partner = {
	all(params){
		return request.get('/api/partner/all', {params})
	},
}

export const message = {
	submit(data){
		return request.post('/api/message/submit', data)
	},
}
