import Vue from "vue";

import AImage from "@/components/views/common/Image.vue"
import ALink from "@/components/views/common/Link.vue"
import APage from "@/components/views/common/Page.vue"
import AEmpty from "@/components/views/common/Empty.vue"
import AVideo from "@/components/views/common/Video.vue"

Vue.component('AImage',AImage);
Vue.component('ALink',ALink);
Vue.component('APage',APage);
Vue.component('AEmpty',AEmpty);
Vue.component('AVideo',AVideo);
