/**
 * 大小写转换
 * @param str
 * @param type  1:首字母大写  2：首页母小写  3：大小写转换  4：全部大写  5：全部小写
 * @returns {string|void|*}
 */
export const changeCase = (str, type = 4)=>{
	switch (type) {
		case 1:
			return str.replace(/\b\w+\b/g, function (word) {
				return word.substring(0, 1).toUpperCase() + word.substring(1).toLowerCase();
			});
		case 2:
			return str.replace(/\b\w+\b/g, function (word) {
				return word.substring(0, 1).toLowerCase() + word.substring(1).toUpperCase();
			});
		case 3:
			return str.split('').map(function (word) {
				if (/[a-z]/.test(word)) {
					return word.toUpperCase();
				} else {
					return word.toLowerCase()
				}
			}).join('');
		case 4:
			return str.toUpperCase();
		case 5:
			return str.toLowerCase();
		default:
			return str;
	}
}

/**
 * 检测密码强度
 * @param str
 * @returns {number}
 */
export const checkPwd = (str)=>{
	let Lv = 0;
	if (str.length < 6) {
		return Lv
	}
	if (/[0-9]/.test(str)) {
		Lv++
	}
	if (/[a-z]/.test(str)) {
		Lv++
	}
	if (/[A-Z]/.test(str)) {
		Lv++
	}
	if (/[.|-|_]/.test(str)) {
		Lv++
	}
	return Lv;
}

/**
 * 过滤html代码
 * @param str
 * @returns {*}
 */
export const filterTag = (str)=>{
	str = str.replace(/&/ig, "&amp;");
	str = str.replace(/</ig, "&lt;");
	str = str.replace(/>/ig, "&gt;");
	str = str.replace(" ", "&nbsp;");
	return str;
}

/**
 * 移除表情
 * @param str
 * @returns {*}
 */
export const removeEmoji = (str)=>{
	return str.replace(/[\ud800-\udbff][\udc00-\udfff]/g, '')
}

/**
 * 创建唯一uuid
 * @returns {string}
 */
export const createUUID = ()=>{
	let s = [];
	let hexDigits = "0123456789abcdef";
	for (let i = 0; i < 36; i++) {
		s[i] = hexDigits.substr(Math.floor(Math.random() * 0x10), 1);
	}
	s[14] = "4";
	s[19] = hexDigits.substr((s[19] & 0x3) | 0x8, 1);
	s[8] = s[13] = s[18] = s[23] = "-";

	return s.join("");
}
