<template>
	<div class="page" v-if="page_total > 1">
		<div class="wrap">
			<div class="text-wrap">
				<div class="text">共{{total}}条</div>
				<div class="text">当前{{page}}/{{page_total}}页</div>
			</div>
			<div class="text-wrap">
				<div class="text link" :class="[page > 1 ? '' : 'disabled']" @click="prev"><i>&lt;</i>上一页</div>
				<template v-if="page_total <= 5">
					<div class="text link number" :class="[item === page ? 'active' : '']" v-for="(item,index) in page_total" @click="toPage(item)">{{item}}</div>
				</template>
				<template v-else>
					<template v-if="page <= 3">
						<div class="text link number" :class="[item === page ? 'active' : '']" v-for="(item,index) in 5" @click="toPage(item)">{{item}}</div>
						<div class="text ">···</div>
						<div class="text link number" @click="toPage(page_total)">{{page_total}}</div>
					</template>
					<template v-else-if="page >= page_total - 2">
						<div class="text link number" @click="toPage(1)">1</div>
						<div class="text ">···</div>
						<div class="text link number" :class="[page_total - (5-item) === page ? 'active' : '']" v-for="(item,index) in 5" @click="toPage(page_total - (5-item))" >{{page_total - (5-item)}}</div>
					</template>
					<template v-else>
						<div class="text link number" @click="toPage(1)">1</div>
						<div class="text ">···</div>
						<div class="text link number" v-for="(item,index) in 1" @click="toPage(page - (2-item))" >{{page - (2-item)}}</div>
						<div class="text number active">{{page}}</div>
						<div class="text link number" v-for="(item,index) in 1" @click="toPage(page + item)" >{{page + item}}</div>
						<div class="text ">···</div>
						<div class="text link number" @click="toPage(page_total)">{{page_total}}</div>
					</template>
				</template>
				<div class="text link" :class="[page < page_total ? '' : 'disabled']" @click="next">下一页<i>&gt;</i></div>
			</div>
		</div>
	</div>
</template>

<script >
export default {
	props:{
		total: {
			type: Number,
			default: 0
		},
		limit: {
			type: Number,
			default: 10
		},
		page: {
			type: Number,
			default: 1
		}
	},
	computed:{
		page_total(){
			return Math.ceil(this.total / this.limit)
		}
	},
	methods:{
		prev(){
			if(this.page > 1){
				this.toPage(this.page - 1);
			}
		},
		next(){
			if(this.page < this.page_total.value){
				this.toPage(this.page + 1);
			}
		},
		toPage(page){
			this.$emit('toPage',page)
		}
	}
}
</script>

<style lang="scss" scoped>
.page{display: inline-block;
	.wrap{display: flex;flex-wrap: wrap;
		.text-wrap{display: flex;justify-content: center}
		.text{min-width:24px;padding: 0 5px; height:24px;text-align: center;line-height:24px; font-size:14px;color:#10266A;margin-right: 5px;user-select: none;word-break: keep-all;
			i{margin: 0 3px;}
		}
		.text.link{cursor: pointer;}
		.text.number{border: 1px solid #D4D4D4;}
		.text.active{border-color: #10266A;background: #10266A; color:#fff;}
		.text.disabled{color:#d4d4d4;cursor:not-allowed;}
	}
}
</style>

