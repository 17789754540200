import jsCookie from "js-cookie"
import {site_config} from "@/config/index.js";

export default {
	namespaced: true,
	state:{
		token:"",
		admin_info:{},
		error:{},
		tags:[
			{title:"控制台",path:"/admin",fixed:true}
		],
	},
	getters:{
		getToken(state){
			let token = state.token;
			if(!token){
				token = jsCookie.get(`${site_config.cookie_prefix}_admin_token`);
			}
			return token;
		}
	},
	mutations:{
		setToken(state,token){
			state.token = token;
			jsCookie.set(`${site_config.cookie_prefix}_admin_token`,token)
		},
		setAdminInfo(state,admin_info){
			state.admin_info = admin_info;
		},
		setError(state,error){
			state.error = error;
		},
		setTags(state,tags){
			state.tags = tags;
		}
	}
}
