<template>
	<a v-if="is_out" class="link" :href="to" target="_blank" rel="nofollow">
		<slot></slot>
	</a>
	<div v-else class="link" @click="toLink">
		<slot></slot>
	</div>
</template>

<script >
export default {
	props:{
		to:{
			type:String,
			default:""
		},
	},
	computed:{
		is_out(){
			return typeof this.to === "string" && this.to.indexOf('http') === 0;
		}
	},
	methods:{
		toLink(){
			this.$router.push(this.to)
		}
	}
}
</script>

<style lang="scss" scoped>
.link{display: block;cursor: pointer;}
</style>
