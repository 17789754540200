/**
 * 获取url参数
 * @param name
 * @returns {string|null}
 */
export const getUrlParams = (name)=>{
	var reg = new RegExp("(^|&)" + name + "=([^&]*)(&|$)");
	var r = decodeURI(window.location.search).substr(1).match(reg);
	if (r != null) return r[2];
	return null;
}

/**
 * 设置url参数
 * @param url
 * @param arg
 * @param arg_val
 * @returns {string|*|string}
 */
export const setUrlParams = (url, arg, arg_val)=>{
	let pattern = arg + '=([^&]*)';
	let replaceText = arg + '=' + arg_val;
	if (url.match(pattern)) {
		let tmp = '/(' + arg + '=)([^&]*)/gi';
		tmp = url.replace(eval(tmp), replaceText);
		return tmp;
	} else {
		if (url.match('[\?]')) {
			return url + '&' + replaceText;
		} else {
			return url + '?' + replaceText;
		}
	}
}

/**
 * 获取全部url参数的json对象
 * @param url
 * @returns {{}}
 */
export const getUrlAllParams = (url)=>{
	var _pa = url.substring(url.indexOf('?') + 1),
		_arrS = _pa.split('&'),
		_rs = {};
	for (var i = 0, _len = _arrS.length; i < _len; i++) {
		var pos = _arrS[i].indexOf('=');
		if (pos === -1) {
			continue;
		}
		var name = _arrS[i].substring(0, pos);
		_rs[name] = decodeURIComponent(_arrS[i].substring(pos + 1));
	}
	return _rs;
}

/**
 * 删除url指定参数，返回url
 * @param url
 * @param name
 * @returns {string|*}
 */
export const deleteUrlParam = (url, name)=>{
	let baseUrl = url.split('?')[0] + '?';
	let query = url.split('?')[1];
	if (query.indexOf(name) > -1) {
		let obj = {}
		let arr = query.split("&");
		for (let i = 0; i < arr.length; i++) {
			arr[i] = arr[i].split("=");
			obj[arr[i][0]] = arr[i][1];
		}
		delete obj[name];
		url = baseUrl + JSON.stringify(obj).replace(/["{}]/g, "").replace(/:/g, "=").replace(/,/g, "&");
		return url
	} else {
		return url;
	}
}

/**
 * 对象转url参数
 * @param data
 * @param isPrefix
 * @returns {string|string}
 */
export const ObjectToParam = (data, isPrefix = false)=>{
	let prefix = isPrefix ? '?' : ''
	let _result = []
	for (let o in data) {
		let value = data[o]
		// 去掉为空的参数
		if (['', undefined, null].includes(value)) {
			continue
		}
		if (value.constructor === Array) {
			value.forEach((_value) => {
				_result.push(encodeURIComponent(o) + '[]=' + encodeURIComponent(_value))
			})
		} else {
			_result.push(encodeURIComponent(o) + '=' + encodeURIComponent(value))
		}
	}
	return _result.length ? prefix + _result.join('&') : ''
}
