import {doViewsRegister} from "@/service/views/common";

export default [
    {
        path:"/",
        component:()=> import('@/layout/Views.vue'),
        children:[
            {
                path: '/',
                component: ()=> import('@/views/Index.vue'),
                meta: {title: '首页'},
            },
            {
                path: '/server',
                component: ()=> import('@/views/Server.vue'),
                meta: {title: '服务项目'},
            },
            {
                path: '/cases',
                component: ()=> import('@/views/cases/Index.vue'),
                meta: {title: '作品案例'},
            },
            {
                path: '/cases/detail',
                component: ()=> import('@/views/cases/Detail.vue'),
                meta: {title: '案例详情'},
            },
            {
                path: '/contact',
                component: ()=> import('@/views/Contact.vue'),
                meta: {title: '联系我们'},
            },
            {
                path: '/about',
                component: ()=> import('@/views/About.vue'),
                meta: {title: '关于我们'},
            },
        ],
        beforeEnter:async (to,from,next)=>{
            await doViewsRegister();
            next();
        }
    },
    {
        path: '*',
        component: ()=> import('@/views/Error.vue'),
        meta: {title: '错误'},
    }
]
