<template>
	<div class="empty">
		<div class="none" v-if="status === 3"></div>
		<div class="wrap" @click="click">
			<div v-if="status === 0" class="image"></div>
			<div class="text">{{ text }}</div>
		</div>
	</div>
</template>
<script>
export default {
	props: {
		status:{
			type:Number,
			default:0,
		},
		message: {
			type: Array,
			default:()=>{
				return  ['加载中','点击加载更多','没有更多内容了','暂无数据']
			}
		},
	},
	computed:{
		text(){
			return this.message[this.status]
		}
	},
	methods:{
		click(){
			if(this.status === 1){
				this.$emit('click')
			}
		}
	}
}
</script>
<style lang="scss" scoped>
.empty { margin: 0 auto;text-align: center;font-size: 16px;display: flex;align-items: center;justify-content: center;flex-direction: column;
	.none {width: 120px;height: 120px;margin: 60px auto 15px;background: url("~@/assets/image/components/empty.png") no-repeat center center;background-size: contain;}
	.wrap {display: flex;align-items: center;cursor: pointer;
		@keyframes rotate {
			0% {transform: rotate(0)}
			100% {transform: rotate(360deg)}
		}
		.image {width: 20px;height: 20px;margin-right: 10px;animation: rotate 2s linear infinite;background: url("~@/assets/image/components/loading.png") no-repeat center center;background-size: contain;}
		.text {color: #666;text-align: center;}
	}
}
</style>
