import {getSentryToken} from "@/service/sentry/common.js";

export default [
	{
		path: '/sentry/auth/login',
		component: ()=> import('@/views/sentry/auth/Login.vue'),
		meta: {title: '登录'},
	},
	{
		path:"/sentry",
		component:()=> import('@/layout/Sentry.vue'),
		children:[
			{
				path: '/sentry',
				component: ()=> import('@/views/sentry/Index.vue'),
				meta: {title: '控制台'},
			},
			{
				path:"/sentry/project",
				component:()=> import('@/views/sentry/project/Layout.vue'),
				children:[
					{
						path: '/sentry/project/dashboard',
						component: ()=> import('@/views/sentry/project/Dashborad.vue'),
						meta: {title: '项目详情'},
					},
					{
						path: '/sentry/project/event',
						component: ()=> import('@/views/sentry/project/Event.vue'),
						meta: {title: '事件列表'},
					},
					{
						path: '/sentry/project/detail',
						component: ()=> import('@/views/sentry/project/Detail.vue'),
						meta: {title: '事件详情'},
					},
					{
						path: '/sentry/project/setting',
						component: ()=> import('@/views/sentry/project/Setting.vue'),
						meta: {title: '项目设置'},
					},
					{
						path: '/sentry/project/node',
						component: ()=> import('@/views/sentry/project/Node.vue'),
						meta: {title: '节点监控'},
					},
					{
						path: '/sentry/*',
						name: 'Error',
						component: ()=> import('@/views/sentry/Error.vue'),
						meta: {title: '错误'},
					}
				]
			}
		],
		beforeEnter:(to,from,next)=>{
			if(!getSentryToken()){
				next('/sentry/auth/login')
			}else{
				next();
			}
		}
	}
]
